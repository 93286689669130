<template>
  <div>
    <form @submit.prevent="onSubmitNewPatient">
      <gro-card :bordered="false">
        <div slot="body">
          <h2 class="mb-3">Add applicant</h2>
          <div class="mb-3">Enter an application on behalf of someone else.</div>
          <gro-input
              class="mb-3"
              required
              id="txt_firstName"
              name="txt_firstName"
              label="First name"
              type="text"
              placeholder="Applicant's first name"
              v-model="model.firstName"
              :is-error="$v.model.firstName.$error"
          />

          <gro-input
              class="mb-3"
              required
              id="txt_lastName"
              name="txt_lastName"
              label="Last name"
              type="text"
              placeholder="Applicant's last name"
              v-model="model.lastName"
              :is-error="$v.model.lastName.$error"
          />

          <gro-input
              class="mb-3"
              required
              id="txt_email"
              name="txt_email"
              label="Email"
              type="text"
              placeholder="Applicant's email address"
              v-model="model.email"

              :is-error="$v.model.email.$error"
          />

          <gro-input
              class="mb-3"
              required
              id="txt_phone"
              name="txt_phone"
              label="Phone number"
              type="tel"
              placeholder="Applicant's phone number"
              v-model="model.phone"
              :is-error="$v.model.phone.$error"
          />

          <gro-inline-date-of-birth
              class="mb-3"
              label="Date of birth"
              required
              v-model="model.dateOfBirth"
              :error-text="$v.model.dateOfBirth.$error"
              :is-error="$v.model.dateOfBirth.$error"
          />

          <div class="gro__input mb-3">
            <label class="gro__input__label" :class="{'has_error': $v.model.gender.$error}">Sex *</label>
            <gro-radio v-model="model.gender" label="Male" value="Male"
                       :input-value="'Male'"></gro-radio>
            <gro-radio v-model="model.gender" class="ml-3" value="Female" label="Female"
                       :input-value="'Female'"></gro-radio>
            <gro-radio v-model="model.gender" class="ml-3" value="Other" label="Other"
                       :input-value="'Other'"></gro-radio>
          </div>


          <gro-input
              class="mb-3"
              placeholder="Applicant's post code"
              label="Post code"
              id="txt_postcode"
              name="txt_postcode"
              type="text"
              v-model="model.postcode"
              :is-error="$v.model.postcode.$error"
              required
              :spinner="false">
          </gro-input>

          <div class="gro__input">
            <label class="gro__input__label">Applicant's current weight*</label>
            <div class="row mb-4">
              <div v-if="userWeightPreference === 'stlbs'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_stones"
                    name="txt_stones"
                    type="number"
                    placeholder="Stones"
                    v-model="unitsModel.weightSt"
                    :is-error="$v.unitsModel.weightSt.$error"
                    :error-text="$v.unitsModel.weightSt.$error ? 'Please enter valid stones between 3 to 38' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    st
                  </div>
                </gro-input>
              </div>
              <div v-if="userWeightPreference === 'stlbs'" class="col d-flex">
                <gro-input
                    class="w-100 pr-2 remove_number_stepper"
                    id="txt_pounds"
                    name="txt_pounds"
                    type="number"
                    placeholder="Pounds"
                    v-model="unitsModel.weightLbs"
                    :is-error="$v.unitsModel.weightLbs.$error"
                    :error-text="$v.unitsModel.weightLbs.$error ? 'Please enter valid pounds between 0 to 14' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    lbs
                  </div>
                </gro-input>
                <unit-preferences></unit-preferences>
              </div>
              <div v-if="userWeightPreference === 'kg'"  class="col d-flex">
                <gro-input
                    class="w-100 pr-2 remove_number_stepper"
                    id="txt_weight"
                    name="txt_weight"
                    type="number"
                    placeholder="Weight"
                    v-model="model.weightGrams"
                    :is-error="$v.model.weightGrams.$error"
                    :error-text="$v.model.weightGrams.$error ? 'Please enter a valid weight between 25kg to 250kg' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    kg
                  </div>
                </gro-input>
                <unit-preferences></unit-preferences>
              </div>
            </div>
          </div>

          <div class="gro__input">
            <label class="gro__input__label">Applicant's current height*</label>
            <div class="row mb-4">
              <div v-show="userHeightPreference === 'ftin'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_feet"
                    name="txt_feet"
                    type="number"
                    placeholder="Feet"
                    v-model="unitsModel.heightFt"
                    :is-error="$v.unitsModel.heightFt.$error"
                    :error-text="$v.unitsModel.heightFt.$error ? 'Please enter valid feet between 3 to 7' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    ft
                  </div>
                </gro-input>
              </div>
              <div v-show="userHeightPreference === 'ftin'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_inches"
                    name="txt_inches"
                    type="number"
                    placeholder="Inches"
                    v-model="unitsModel.heightIn"
                    :is-error="$v.unitsModel.heightIn.$error"
                    :error-text="$v.unitsModel.heightIn.$error ? 'Please enter valid inches between 0 to 12' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    in
                  </div>
                </gro-input>
              </div>
              <div v-show="userHeightPreference === 'cm'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_height"
                    name="txt_height"
                    placeholder="Height"
                    type="number"
                    v-model="model.heightCm"
                    :is-error="$v.model.heightCm.$error"
                    :error-text="$v.model.heightCm.$error ? 'Please enter a valid height between 50 to 255 centimetres' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    cm
                  </div>
                </gro-input>
              </div>
            </div>

          </div>


<!--          <div class="row mb-4">-->
<!--            <div class="col">-->
<!--              <gro-input-->
<!--                  label="Applicant's current weight *"-->
<!--                  id="txt_weight"-->
<!--                  name="txt_weight"-->
<!--                  type="number"-->
<!--                  placeholder="Weight"-->
<!--                  v-model="model.weightGrams"-->
<!--                  :is-error="$v.model.weightGrams.$error"-->
<!--                  :error-text="$v.model.weightGrams.$error ? 'Please enter a valid weight between 25kg to 250kg' : ''"-->
<!--                  :spinner="false">-->
<!--                <div slot="rightIcon">-->
<!--                  kg-->
<!--                </div>-->
<!--              </gro-input>-->

<!--            </div>-->
<!--            <div class="col">-->
<!--              <gro-input-->
<!--                  label="Applicant's current height *"-->
<!--                  id="txt_height"-->
<!--                  name="txt_height"-->
<!--                  placeholder="Height"-->
<!--                  type="number"-->
<!--                  v-model="model.heightCm"-->
<!--                  :is-error="$v.model.heightCm.$error"-->
<!--                  :error-text="$v.model.heightCm.$error ? 'Please enter a valid height between 50 to 255 centimetres' : ''"-->
<!--                  :spinner="false">-->
<!--                <div slot="rightIcon">-->
<!--                  cm-->
<!--                </div>-->
<!--              </gro-input>-->
<!--            </div>-->
<!--            <div class="col">-->
<!--              <unit-preferences></unit-preferences>-->
<!--            </div>-->
<!--          </div>-->

          <div class="gro__select gro__input mb-3">
            <label for="txt_source" class="gro__input__label"
                   :class="{'has_error': $v.model.source.$error}">Referral source? *</label>
            <select id="txt_source" v-model="model.source"
                    class="gro__select__native__element gro__input__element">
              <option :value="null" label="Referral source" selected></option>
              <option  v-for="item, index in referralSources" :key="`item-${index}`" :label="item.name" :value="item.name">name</option>
            </select>
          </div>

          <div class="gro__select gro__input mb-3">
            <label for="txt_ethnicity" class="gro__input__label"
                   :class="{'has_error': $v.model.ethnicity.$error}">Ethnicity? *</label>
            <select id="txt_ethnicity" v-model="model.ethnicity"
                    class="gro__select__native__element gro__input__element">
              <option :value="null" label="Applicant's ethnicity" selected></option>
              <option v-for="item, index in ethnicities" :key="`item-${index}`" :label="item.label" :value="item.enumValue">{{item.label}}</option>
            </select>
          </div>

          <div class="gro__input mb-3">
            <label class="gro__input__label">Preferred method of delivery</label>
            <gro-radio v-model="model.preferredContact" label="Digital" value="Digital"
                       :input-value="'Digital'"></gro-radio>
            <gro-radio v-model="model.preferredContact" class="ml-3" label="Face-to-face" value="FacetoFace"
                       :input-value="'FacetoFace'"></gro-radio>
            <gro-radio v-model="model.preferredContact" class="ml-3" label="Digital + Face-to-face" value="DigitalFacetoFace"
                       :input-value="'DigitalFacetoFace'"></gro-radio>
            <gro-radio v-model="model.preferredContact" class="ml-3" value="Offline" label="Offline"
                       :input-value="'Offline'"></gro-radio>
          </div>

          <gro-card class="delivery-address mb-3" v-if="model.preferredContact === 'Offline'">
            <div slot="body">
              <h4 class="mb-2">Offline pack delivery address</h4>
              <gro-input
                  class="mb-2"
                  id="txt_addressline1"
                  name="txt_addressline1"
                  label="Address line 1"
                  type="text"
                  placeholder="Address line 1"
                  v-model="model.deliveryAddress.addressLine1"
                  :is-error="$v.model.deliveryAddress.addressLine1.$error"
              />
              <gro-input
                  class="mb-2"
                  id="txt_addressline2"
                  name="txt_addressline2"
                  label="Address line 2"
                  type="text"
                  placeholder="Address line 1"
                  v-model="model.deliveryAddress.addressLine2"
                  :is-error="$v.model.deliveryAddress.addressLine2.$error"
              />

              <div class="row mb-1">
                <div class="col">
                  <gro-input
                      label="Town/city"
                      id="txt_townCity"
                      name="txt_townCity"
                      type="text"
                      placeholder="Town/city"
                      v-model="model.deliveryAddress.townCity"
                      :is-error="$v.model.deliveryAddress.townCity.$error">
                  </gro-input>
                </div>
                <div class="col">
                  <gro-input
                      label="Postcode"
                      id="txt_postcode"
                      name="txt_postcode"
                      type="text"
                      placeholder="Postcode"
                      v-model="model.deliveryAddress.postcode"
                      :is-error="$v.model.deliveryAddress.postcode.$error">
                  </gro-input>
                </div>
              </div>
            </div>
          </gro-card>


          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2" :class="{'has_error': $v.model.referredServices.$error}">
                Preferred weight management service *
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in referralServicesList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox :disabled="(item.name === 'Maidstone Leisure Centre' && model.preferredContact === 'Digital')"  v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-1">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Local services requested
              </label>
            </div>
            <div class="d-flex flex-wrap flex-column" style="height: 130px;">
              <div v-for="item, index in services" :key="`goal-${index}`" class="mb-2 mr-1">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="divider mb-4"></div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">
                Do you drink alcohol? *
              </label>
            </div>
            <div class="d-flex">
              <div class="d-inline-flex">
                <gro-radio v-model="model.drinkAlcohol" :value="true" label="Yes"
                           :has-error="$v.model.drinkAlcohol.$error"></gro-radio>
              </div>
              <div class="d-inline-flex pl-3">
                <gro-radio v-model="model.drinkAlcohol" :value="false" label="No"
                           :has-error="$v.model.drinkAlcohol.$error"></gro-radio>
              </div>
            </div>
          </div>

          <div v-show="model.drinkAlcohol === true">
            <div class="gro__select gro__input mb-3">
              <label for="txt_alcoholHowOften" class="gro__input__label">How often do you have a drink containing alcohol?</label>
              <select id="txt_alcoholHowOften" v-model="model.alcoholFrequency"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option v-for="(item, index) in alcoholFrequencyList" :key="`item-${index}`" :value="item.enumValue" :label="item.label"></option>
              </select>
            </div>

            <div class="gro__select gro__input mb-3">
              <label for="txt_standardDrinks" class="gro__input__label">How many standard drinks containing alcohol do you have on a typical day?</label>
              <select id="txt_standardDrinks" v-model="model.dailyCountAlcohol"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option v-for="(item, index) in dailyAlcoholList" :key="`item-${index}`" :value="item.enumValue" :label="item.label"></option>
              </select>
            </div>

            <div class="gro__select gro__input mb-3">
              <label for="txt_alcoholHowOften" class="gro__input__label">How often do you have six or more drinks on one occasion?</label>
              <select id="alcoholHowOftenSixOrMore" v-model="model.overSixCountAlcohol"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option value="Daily" label="Daily or almost daily">Daily or almost daily</option>
                <option value="Weekly" label="Weekly">Weekly</option>
                <option value="Monthly" label="Monthly">Monthly</option>
                <option value="LessThanMonthly" label="Less than monthly">Less than monthly</option>
                <option value="Never" label="Never">Never</option>
              </select>
            </div>
          </div>


          <div class="divider mb-4"></div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Applicant's GP surgery</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.gp"
                id="ajax3"
                label="name"
                track-by="name"
                placeholder="Type to search for a surgery..."
                open-direction="bottom"
                :options="surgeries"
                :multiple="false"
                :searchable="true"
                :loading="isSurgeriesLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindSurgeries">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedSurgery"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>

          <div class="mb-3">
              <div class="gro__input">
                <label class="gro__input__label">Health conditions</label>
              </div>
              <multiselect
                  class="gro__multi__select"
                  v-model="model.conditions"
                  id="ajax"
                  label="name"
                  track-by="name"
                  placeholder="Type to search for a condition..."
                  open-direction="bottom"
                  :options="conditions"
                  :multiple="true"
                  :searchable="true"
                  :loading="isConditionsLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :options-limit="10"
                  :limit="10"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="asyncFindConditions">
                <template slot="noOptions">
                  No results to show, begin typing
                </template>
                <template slot="clear" slot-scope="props">
                  <div class="multiselect__clear" v-if="selectedConditions"
                       @mousedown.prevent.stop="clearAll(props.search)"></div>
                </template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </multiselect>
          </div>


          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Medications</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.medications"
                id="ajax2"
                label="name"
                track-by="name"
                placeholder="Type to search for a medication..."
                open-direction="bottom"
                :options="medications"
                :multiple="true"
                :searchable="true"
                :loading="isMedicationsLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindMedications">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedMedications"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2" :class="{'has_error': $v.model.goals.$error}">
                What are the applicant’s health improvement goals? *
              </label>
            </div>
            <div class="d-flex flex-wrap flex-column" style="height: 110px;">
              <div v-for="item, index in goals" :key="`goal-${index}`" class="mb-2">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="gro__input mb-2">
            <label class="gro__input__label">Requires support with learning difficulties or disabilities</label>
            <gro-radio v-model="model.requireDisabilitySupport" label="Yes" :value="true"
                       input-value="Yes"></gro-radio>
            <gro-radio v-model="model.requireDisabilitySupport" class="ml-3" :value="false" label="No"
                       input-value="No"></gro-radio>
          </div>

          <gro-button
              :loading="isProcessing"
              :disabled="isProcessing"
              loading-title="Processing..."
              style="position: relative; z-index: 0"
              class="w-100"
              type="submit"
              size="md"
              gradient
              glowing
              centered>
           Save
          </gro-button>

        </div>
      </gro-card>
    </form>
  </div>
</template>

<script>
import referralClient from "@/helpers/referralClient";
import {validationMixin} from 'vuelidate'
import conditionsMultiSelectMixin from '@/mixins/conditionsMultiSelectMixin'
import medicationsMultiSelectMixin from '@/mixins/medicationsMultiSelectMixin'
import surgeriesSelectMixin from "@/mixins/surgeriesSelectMixin"
import {
  required,
  requiredIf,
  email,
  minLength,
  minValue,
  maxValue,
  numeric, maxLength
} from 'vuelidate/lib/validators'
import moment from "moment";
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
import UnitPreferences from "@/views/Portal/_components/UnitPreferences";

const minAge = function(value) {
  let minAge = 18;
  let now = moment();
  let dobMoment = moment(value);
  let differenceInYears = now.diff(dobMoment, 'years');
  return differenceInYears >= minAge & differenceInYears < 120;
}

export default {
  name: "AddNewPatient",
  mixins: [validationMixin, conditionsMultiSelectMixin, medicationsMultiSelectMixin, surgeriesSelectMixin],
  components: {UnitPreferences, Multiselect},
  props: ['ethnicities', 'goals', 'referralServicesList', 'services', 'alcoholFrequencyList', 'dailyAlcoholList', 'referralSources'],
  validations: {
    model: {
      deliveryAddress: {
        addressLine1: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        },
        addressLine2: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        },
        townCity: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        },
        postcode: {
          required: requiredIf(function (form) {
            return this.model.preferredContact === 'Offline'
          }),
        }
      },
      firstName: {
        required,
        maxLength: maxLength(50)
      },
      lastName: {
        required,
        maxLength: maxLength(50)
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        numeric,
        minLength: minLength(10)
      },
      dateOfBirth: {
        required,
      },
      gender: {
        required
      },
      postcode: {
        required
      },
      drinkAlcohol: {
        required
      },
      weightGrams: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'kg'
        }),
        maxValue: maxValue(250),
        minValue: minValue(25)
      },
      heightCm: {
        required: requiredIf(function () {
          return this.userHeightPreference === 'cm'
        }),
        maxValue: maxValue(255),
        minValue: minValue(50)
      },
      ethnicity: {
        required
      },
      goals: {
        required
      },
      referredServices: {
        required
      },
      source: {
        required
      }
    },
    unitsModel: {
      weightSt: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'stlbs'
        }),
        maxValue: maxValue(38),
        minValue: minValue(3)
      },
      weightLbs: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'stlbs'
        }),
        maxValue: maxValue(14),
        minValue: minValue(0)
      },
      heightFt: {
        required: requiredIf(function () {
          return this.userHeightPreference === 'ftin'
        }),
        maxValue: maxValue(7),
        minValue: minValue(3)
      },
      heightIn: {
        required: requiredIf(function () {
          return this.userHeightPreference === 'ftin'
        }),
        maxValue: maxValue(12),
        minValue: minValue(0)
      },
    }
  },
  data() {
    return {
      isProcessing: false,
      model: {
        name: '',
        email: null,
        phone: null,
        dateOfBirth: '',
        gender: '',
        postcode: '',
        weightGrams: null,
        heightCm: null,
        ethnicity: '',
        gp: null,
        conditions: [],
        medications: [],
        healthGoals: [],
        goals: [],
        referredServices: [],
        preferredContact: null,
        services: [],
        requireDisabilitySupport: null,
        deliveryAddress: {
          addressLine1: '',
          addressLine2: '',
          postcode: '',
          townCity: ''
        },
        drinkAlcohol: null,
        alcoholFrequency: null,
        dailyCountAlcohol: null,
        overSixCountAlcohol: null,
        source: null,
      },
      unitsModel: {
        weightSt: null,
        weightLbs: null,
        heightFt: null,
        heightIn: null,
      }
    }
  },
  watch: {
    'model.preferredContact': {
      handler(value) {
        if (value === 'Digital') {
          this.referralServicesList
              .find(x => (x.name === 'Maidstone Leisure Centre')).checked = false;
        }
      }
    },
    goals: {
      handler() {
        this.model.goals = this.goals
            .filter(x => x.checked === true)
            .map(x => ({
              name: x.name,
              goalId: x.goalId
            }))
      },
      deep: true
    },
    referralServicesList: {
      handler() {
        this.model.referredServices = this.referralServicesList
            .filter(x => x.checked === true)
            .map(x => ({id: x.id}))
      },
      deep: true
    },
    services: {
      handler() {
        this.model.services = this.services
            .filter(x => x.checked === true)
            .map(x => ({id: x.id}))
      },
      deep: true
    },
  },
  methods: {
    onSubmitNewPatient() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isProcessing = true;
      let updatedModelForApi = {...this.model};
      updatedModelForApi.dateOfBirth = moment(this.model.dateOfBirth).format('YYYY-MM-DD');

      if (this.userWeightPreference === 'stlbs') {
        let totalStones = (this.unitsModel.weightSt * 14);
        let totalLbs = parseInt(totalStones) + parseInt(this.unitsModel.weightLbs);
        let lbsToGrams = totalLbs / 0.0022046;
        updatedModelForApi.weightGrams = parseInt(lbsToGrams);
      } else if(this.userWeightPreference === 'kg') {
        updatedModelForApi.weightGrams = parseInt(this.model.weightGrams * 1000);
      }

      if (this.userHeightPreference === 'ftin') {
        let totalFt = (this.unitsModel.heightFt * 12)
        let totalInches = parseInt(totalFt) + parseInt(this.unitsModel.heightIn)
        let inchesToCm = totalInches / 0.39370;
        updatedModelForApi.heightCm = parseInt(inchesToCm)
      } else if(this.userHeightPreference === 'cm') {
        updatedModelForApi.heightCm = parseInt(this.model.heightCm);
      }


      delete updatedModelForApi.name;

      referralClient.post('/Lead', updatedModelForApi)
        .then((res) => {
          this.$emit('success', true);
          this.$toast.success('User added')
        })
        .catch((err) => {
          this.isProcessing = false;
          this.$toast.error('User failed')
        })
    }
  },
  computed: {
    userWeightPreference () {
      return this.$store.getters['User/getUserPreferences'].weight
    },
    userHeightPreference () {
      return this.$store.getters['User/getUserPreferences'].height
    }
  }
}
</script>

<style lang="scss">
.delivery-address {
  background: #fafafa;
  h4 {
    font-size: 14px;
  }
}
</style>